import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import Navbar from '../Design/Navbar';
import Footer from '../../components/Design/Footer';
import TakeFormClass from '../../components/inc/takeFormClass';
import swal from 'sweetalert2';
import '../styles/TakeClass.css';
function TakeClass() {
  const { user, isAuthenticated, isLoading } = useAuth0();
  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (!isAuthenticated) {
    swal.fire({
      title: 'Debes iniciar sesión',
      text: 'No Puedes tomar clases si no estas logueado',
      icon: 'warning',
    });
    return <Navigate to="/" />;
  }
  return (
    <div>
      {isAuthenticated ? (
        <div className='takeclass-body'>
          <Navbar />

          <div className="take-class-container">
            <TakeFormClass></TakeFormClass>
          </div>

          <Footer />
        </div>
      ) : (
        //else
        <div>
          <h1>No Puedes tomar clases si no estas logueado</h1>
          {/* Puedes agregar aquí cualquier otro contenido que desees mostrar */}
        </div>
      )}
    </div>
  );
}
export default TakeClass;
